/* @value white, waveformBlack from '../../styles/variables/colors.css'; */

.temp {
  border: "2px solid black";
}

.ekgRecording {
  overflow-y: scroll;
  position: relative;
  background-color: white;
}
.topSVG {
  background-color: transparent;
  position: absolute;
  pointer-events: none;
}
.topSVG path {
  fill: none;
  /* stroke: waveformBlack; */
  stroke: #3d2525;
  stroke-width: 1.5px;
  stroke-linejoin: round;
}
.canvas {
  position: absolute;
}
