@value contentWrapper from '../../../styles/page.css';

.contentWrapper {
    composes: contentWrapper;
    padding: 50px 55px;
    overflow: auto;
}
.accessErrorHeadline {
    font-size: 2rem;
    line-height: 1.04;
}
.accessErrorDesc {
    font-size: 20px;
    line-height: 1.8;
}
.accessErrorInstr {
    font-size: 16px;
    line-height: 1.8;
}
